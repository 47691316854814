<template>
    <div class="row">
        <div class="col">
            <div class="card grid-margin">
                <div class="card-body">
                    <h1 class="card-title">
                        <i class="mdi mdi-office-building"></i>
                        Elections Headquarters
                    </h1>

                    <p class="card-description">The headquarters for the elections module</p>

                    <div class="row">
                        <div class="col-12">
                            <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div class="row">
                                    <div class="col-xs-12 col-4">
                                        <button type="button" class="btn btn-primary" @click="addElection">
                                            <i class="mdi mdi-plus"></i>
                                            Add Election
                                        </button>
                                    </div>

                                    <div class="col-xs-12 col-8">
                                        <toolbar
                                            @show="val => params.per_page = val"
                                            @search="val => params.keyword = val" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <template v-if="elections.length">
                                            <div class="table-responsive">
                                                <elections-table
                                                    @edit="editElection"
                                                    :elections="elections"
                                                    @delete="deleteElection" />
                                            </div>

                                            <app-pagination
                                                v-if="pageDetails && pageDetails.total"
                                                :pageDetails="pageDetails"
                                                @navigate="getElections($event)" />
                                        </template>

                                        <template v-else>
                                            <p class="lead mt-5 mb-5 text-center">There are no elections in the system.</p>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<modal
			:show="showModal"
            :election="modalElection"
            @save-election="saveElection"
			@update:show="val => showModal = val" />
    </div>
</template>

<script>
    import Modal from './Modal';
    import { mapActions, mapGetters } from 'vuex';
    import ElectionsTable from './Table';

    export default {
        components: {
            Modal,
            ElectionsTable,
        },

        data() {
            return {
                showModal: false,
                modalElection: {},
                params: {
                    paginate: true,
                    per_page: null,
                    keyword: null
                }
            };
        },

        computed: {
            ...mapGetters({
                elections: 'Elections/getAll',
                pageDetails: 'Elections/getPageDetails'
            })
        },

        watch: {
            params: {
                deep: true,
                handler() {
                    this.getElections();
                }
            }
        },

		methods: {
			...mapActions({
                load: 'Elections/all',
                store: 'Elections/store',
                update: 'Elections/update',
                delete: 'Elections/delete'
            }),

            /**
             * Open the add election modal.
             *
             * @return {Undefined}
             */
            addElection() {
                this.showModal = true;
            },

            /**
             * Open the edit election modal.
             *
             * @param {Object} election
             * @return {Undefined}
             */
            editElection(election) {
                this.showModal = true;
                this.modalElection = { ...election };
            },

            /**
             * Get all elections from the server.
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getElections(query = {}) {
                for (let param of Object.keys(this.params)) {
                    if (this.params[param]) {
                        query[param] = this.params[param];
                    }
                }

                this.load(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Save the election in the backend.
             *
             * @param {Object} election
             * @return {Undefined}
             */
            saveElection(election) {
                let keyword = 'added';
                let savingMethod = this.store;
                let payload = { data: election };

                if (election.id !== 'undefined' && election.id) {
                    keyword = 'updated';
                    payload.id = election.id;
                    savingMethod = this.update;
                }

                savingMethod(payload)
                    .then(response => {
                        this.notify(`The election was ${keyword} successfully.`);
                        this.getElections();
                        this.showModal = false;
                        this.modalElection = {};
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

			/**
			 * Delete the specified election.
             * Request for a deletion confirmation.
			 *
			 * @param  {Number} id
			 * @return {Undefined}
			 */
			deleteElection(id) {
				Swal({
					title: "Are you sure?",
					text: "Once deleted, you will not be able to recover this election and its data!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Delete it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.delete(id)
							.then(() => {
								this.notify('The election was deleted successfully.');
								this.getElections();
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
			}
        },

        mounted() {
            this.getElections();
        }
    }
</script>

<style lang="scss" scoped>
</style>


