<template>
    <table class="table dataTable table-striped no-footer" role="grid">
        <thead>
            <tr role="row">
                <th class="sorting">Name</th>
                <th class="sorting">No. of Candidates</th>
                <th class="sorting">Date</th>
                <th class="sorting">Status</th>
                <th class="sorting">Created By</th>
                <th class="sorting">Created At</th>
                <th class="sorting">Updated At</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(election, index) in elections" :key="index">
                <td>{{ election.name }}</td>
                <td>{{ election.candidates.length }} </td>
                <td>{{ election.date | formattedDate }}</td>
                <td>{{ election.status.name }}</td>
                <td>{{ election.user.name }}</td>
                <td>{{ election.created_at | formattedDateTime }}</td>
                <td>{{ election.updated_at | formattedDateTime }}</td>
                <td>
                    <router-link
                        tag="button"
                        :to="{ name: 'view_election', params: { id: election.id }}"
                        class="btn btn-success btn-action mr-3" data-toggle="popover" data-content="View Election">
                            <i class="mdi mdi-eye"></i>
                    </router-link>

                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', election)" data-toggle="popover" data-content="Edit Election">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', election.id)" data-toggle="popover" data-content="Delete Election">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: ['elections']
    }
</script>